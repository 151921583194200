<div class="root">
  <div class="clr-col-10 clr-offset-1 clr-col-sm-10 clr-offset-sm-1 clr-col-md-12 clr-offset-md-0 container">
    <div>
      <h2
        [innerHTML]="'COMPLIANCE.MSFT_NOT_CONNECTED' | translate"
        class="pb-x2"
      ></h2>
      <div
        [innerHTML]="'COMPLIANCE.MSFT_MUST_BE_CONNECTED' | translate"
        class="subheader clr-col-sm-10 clr-offset-sm-1 clr-col-md-6 clr-offset-md-3"
      ></div>
      <div>
        <div class="intelligent-hub mv-x4"></div>
        @if (deviceStore !== MAC_DEVICE) {
          <button
            (click)="connectNow()"
            class="btn btn-primary mr-x0 mb-x8"
          >
            {{ 'COMPLIANCE.CONNECT_NOW' | translate }}
          </button>
        } @else {
          <div class="hub-buttons">
            <div class="button-group">
              <div>
                {{ 'COMPLIANCE.MAC_OS' | translate }}
              </div>
              <button
                (click)="connectNow(MAC_DEVICE)"
                class="btn btn-primary mr-x0 mb-x8"
              >
                {{ 'COMPLIANCE.CONNECT_NOW' | translate }}
              </button>
            </div>
            <div class="separator"></div>
            <div class="button-group">
              <div>
                {{ 'COMPLIANCE.IPAD_OS' | translate }}
              </div>
              <button
                (click)="connectNow(IOS_DEVICE)"
                class="btn btn-primary mr-x0 mb-x8"
              >
                {{ 'COMPLIANCE.CONNECT_NOW' | translate }}
              </button>
            </div>
          </div>
        }
        <div class="line ph-x1 mb-x4">
          <span class="ph-x1 subheader">
            {{ 'COMMON_MESSAGES.OR' | translate }}
          </span>
        </div>
        <h4 class="subheader mv-x4">
          {{ 'COMPLIANCE.NO_INTELLIGENT_HUB' | translate }}
        </h4>
        <ol>
          <li class="mb-x3">
            <span>{{ downloadMessage }}</span>
            @switch (deviceStore) {
              @case (MAC_DEVICE) {
                <div class="hub-buttons">
                  <div class="button-group">
                    <div>
                      {{ 'COMPLIANCE.MAC_OS' | translate }}
                    </div>
                    <a
                      [href]="pkgDirectDownloadUrlMacOS"
                      rel="noopener"
                      target="_blank"
                    >
                      <div class="hub-download">
                        {{ 'COMPLIANCE.DOWNLOAD_HUB_BUTTON' | translate }}
                      </div>
                    </a>
                  </div>
                  <div class="separator"></div>
                  <div class="button-group">
                    <div>
                      {{ 'COMPLIANCE.IPAD_OS' | translate }}
                    </div>
                    <a
                      [href]="downloadFromStoreUrl"
                      rel="noopener"
                      target="_blank"
                    >
                      <span class="mt-x1">
                        <dpa-app-store-logo [deviceStore]="IOS_DEVICE" />
                      </span>
                    </a>
                  </div>
                </div>
              }
              @case (WINDOWS_DEVICE) {
                <div class="hub-buttons">
                  <div class="button-group">
                    <a
                      [href]="pkgDirectDownloadUrlWindows"
                      rel="noopener"
                      target="_blank"
                      class="btn btn-primary btn-round"
                    >
                      {{ 'COMPLIANCE.DOWNLOAD_HUB_BUTTON_WINDOWS_X86' | translate }}
                    </a>
                  </div>
                  <div class="separator"></div>
                  <div class="button-group">
                    <a
                      [href]="pkgDirectDownloadUrlWindowsARM64"
                      rel="noopener"
                      target="_blank"
                      class="btn btn-primary btn-round"
                    >
                      {{ 'COMPLIANCE.DOWNLOAD_HUB_BUTTON_WINDOWS_ARM64' | translate }}
                    </a>
                  </div>
                </div>
              }
              @default {
                <a
                  [href]="downloadFromStoreUrl"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="mt-x1">
                    <dpa-app-store-logo [deviceStore]="deviceStore" />
                  </span>
                </a>
              }
            }
          </li>
          <li>
            {{ 'COMPLIANCE.OPEN_INTELLIGENT_HUB' | translate }}
          </li>
          <li>
            {{ 'COMPLIANCE.MSFT_RETURN_TO_CONNECT' | translate }}
          </li>
        </ol>
      </div>
    </div>
    <div class="footer mb-x1">
      {{ 'COMPLIANCE.NO_ACCESS_CONTACT_IT' | translate }}
    </div>
  </div>
</div>
